<template>
  <v-container class="is-md py-12">
    <!-- header -->
    <v-row align="center" class="mb-3">
      <v-col cols="6">
        <v-btn
          plain small :ripple="false" text
          to="/cases/search"
          class="unwidth px-0"
        >
          <v-icon class="mr-1">
            mdi-keyboard-backspace
          </v-icon>
          Back to boxes
        </v-btn>
      </v-col>

      <v-col cols="6" class="text-right">
        <v-chip label color="primary--gradient" light>
          <span class="font-weight-semibold text-uppercase">Creating new box</span>
        </v-chip>
      </v-col>
    </v-row>

    <BoxForm
      v-if="formDataReady"
      :value="forms"
      :markets="markets"
      :categories="categories"
      :loading="loading.submit"
      is-new
      @submit="onSubmit"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import BoxForm from './components/BoxForm'

export default {
  metaInfo: {
    title: 'Create box',
  },
  components: {
    BoxForm,
  },
  data() {
    return {
      loading: {
        submit: false,
        data: false,
      },
      categories: null,
      markets: null,
      forms: {
        slug: null,
        market: null,
        creator: null,
        price: null,
        type: null,
        disabled: false,
        name: null,
        description: null,
        icon: null,
        categories: [],
        tags: [],
        data: {},
        content: {
          items: [],
          odds: {},
          edge: null,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
    formDataReady() {
      return !!this.markets && !!this.categories
    },
  },
  async created() {
    this.getFormData()
  },
  methods: {
    async getFormData() {
      try {
        this.loading.data = true
        const [markets, categories] = await Promise.all([
          this.$socket.request('admin.markets.get'),
          this.$socket.request('admin.cases.categories'),
        ])

        this.markets = markets
        this.categories = categories
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.data = false
      }
    },
    async onSubmit(forms) {
      if (this.loading.submit) return
      this.loading.submit = true

      console.log(forms)

      try {
        await this.$socket.request('admin.cases.set', {
          ...forms,
          content: {
            items: forms.content.items.map(i => i._id),
            edge: forms.content.edge,
            odds: forms.content.odds,
          },
        })

        this.$toast.success('Box created successfully')
        this.$router.push('/cases/search')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.submit = false
      }
    },
  },
}
</script>
