<template>
  <div>
    <!-- general -->
    <v-card class="pa-5 mb-4" color="grey700">
      <v-row>
        <v-col cols="12" md="3">
          <label class="text-body-2 d-block mb-1">Box icon</label>

          <v-hover>
            <template #default="{hover}">
              <v-img class="grey400" :src="forms.icon" :aspect-ratio="1" contain>
                <v-fade-transition>
                  <div v-if="!forms.icon || hover" class="full-height item-forms-icon">
                    <v-btn
                      v-if="forms.icon"
                      icon small absolute top
                      right
                      @click="forms.icon = null"
                    >
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>

                    <FileDropArea
                      :files="files"
                      :loading="uploading"
                      disable-hint
                      accept="image/*"
                      class="rounded-0 full-height"
                      @submit="onIconSubmitted"
                      @rejectedFiles="$toast.error('File type invalid')"
                    >
                      <span class="mb-2">Drop file or</span>
                    </FileDropArea>
                  </div>
                </v-fade-transition>
              </v-img>
            </template>
          </v-hover>
        </v-col>

        <v-col cols="12" md="9">
          <v-row>
            <v-col cols="12" md="5">
              <fieldset>
                <label class="text-body-2 d-block mb-1">Box name</label>
                <v-text-field
                  v-model="forms.name" filled dense color="primary500"
                  placeholder="Epic Gamer Box"
                  hide-details
                />
              </fieldset>
            </v-col>

            <v-col cols="12" md="4">
              <fieldset>
                <label class="text-body-2 d-block mb-1">Url slug</label>
                <v-text-field
                  v-model="forms.slug" filled dense color="primary500"
                  placeholder="epic-gamer-box"
                  hide-details
                />
              </fieldset>
            </v-col>

            <v-col cols="12" md="3">
              <label class="text-body-2 d-block mb-1">Box status</label>

              <v-card
                color="grey500" outlined class="px-3 py-2"
                @click="forms.disabled = !forms.disabled"
              >
                <v-switch
                  :input-value="!forms.disabled"
                  color="primary" class="pa-0 ma-0"
                  hide-details
                >
                  <template #label>
                    <div>
                      <h4 class="text-body-2 grey100--text">
                        {{ forms.disabled ? 'Disabled' : 'Enabled' }}
                      </h4>
                    </div>
                  </template>
                </v-switch>
              </v-card>
            </v-col>

            <v-col cols="12">
              <fieldset>
                <label class="text-body-2 d-block mb-1">Item description (md editor soon)</label>
                <v-textarea
                  v-model="forms.description" hide-details dense color="primary500"
                  placeholder="Generic description that fits all variants..." rows="4"
                  filled auto-grow
                />
              </fieldset>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="4">
          <fieldset>
            <label class="text-body-2 d-block mb-1">Categories</label>
            <v-select
              v-model="forms.categories"
              :items="categories"
              multiple
              item-text="name"
              item-value="_id"
              filled dense
              placeholder="Tech"
              hide-details
            />
          </fieldset>
        </v-col>

        <v-col cols="12" md="4">
          <fieldset>
            <label class="text-body-2 d-block mb-1">Box market</label>
            <v-select
              v-model="forms.market"
              :items="markets"
              item-text="name"
              item-value="_id"
              filled dense
              placeholder="Pener"
              hide-details
            >
              <v-chip
                v-if="forms.market"
                slot="prepend-inner"
                label small color="primary--gradient" light
                class="font-weight-bold ml-0 px-2 text-uppercase"
              >
                {{ market.slug }}
              </v-chip>
            </v-select>
          </fieldset>
        </v-col>

        <v-col cols="12" md="4">
          <fieldset>
            <label class="text-body-2 d-block mb-1">Box type</label>
            <v-combobox
              v-model="forms.type"
              :items="['promo', 'level', 'daily']"
              filled clearable dense
              placeholder="regular (leave empty)"
              hide-details
            />
          </fieldset>
        </v-col>

        <!-- <v-col cols="12">
            <v-divider class="my-1" />
          </v-col> -->
      </v-row>
    </v-card>

    <v-card class="pa-5 mb-4" color="grey700">
      <v-row dense>
        <v-col cols="12" md="6">
          <h4 class="text-body-1 primary500--text font-weight-bold">
            Box content
          </h4>
          <p class="text-caption mb-1">
            Add, remove or change odds of items in this box.
          </p>
        </v-col>
        <v-col cols="12" md="6" class="text-right" align-self="center">
          <v-btn
            color="primary--gradient" light
            :dark="!forms.market" :disabled="!forms.market"
            @click="onAddItem"
          >
            <v-icon class="mr-2">
              mdi-plus
            </v-icon>
            Add items
          </v-btn>
        </v-col>

        <!-- items -->
        <v-col v-for="(item, i) in forms.content.items" :key="i" cols="12">
          <v-row dense>
            <v-col cols="6">
              <v-sheet class="py-2 rounded-l px-3 d-flex align-center" color="grey500">
                <v-img
                  :src="item.icon" :aspect-ratio="1" width="40"
                  class="flex-grow-0 rounded grey400 mr-3"
                />

                <div>
                  <h4 class="text-body-2 lh-1-4 font-weight-medium" v-text="item.name" />
                  <h6 class="text-caption lh-1-4 text-caption text--secondary">
                    <span v-if="item.brand" v-text="item.brand" />

                    <span v-show="item.brand && item.rarity" class="mx-2">&middot;</span>

                    <span v-if="item.rarity">
                      <v-icon x-small :color="item.rarity.color" class="mr-1">
                        mdi-circle
                      </v-icon>
                      <span v-text="item.rarity.name" />
                    </span>
                  </h6>
                </div>

                <v-spacer />

                <MarketPrice
                  hide-zero-value class="primary500--text mr-1 font-weight-semibold"
                  :market="market" :value="getItemMarketPrice(item, forms.market)"
                />
              </v-sheet>
            </v-col>

            <v-col cols="2">
              <v-sheet
                class="px-3 fill-height d-flex justify-end align-center grey200--text font-weight-semibold"
                color="grey500"
              >
                +
                <MarketPrice
                  class="ml-1 "
                  :market="market" :value="getItemEv(item)"
                />
              </v-sheet>
            </v-col>

            <v-col cols="3">
              <v-currency-field
                :value="odds[item._id] || null"
                :options="{
                  currency: null,
                  allowNegative: false,
                  precision: 4,
                  valueRange: { max: 100 - 0.0001, min: 0.0001}
                }"
                placeholder="Item odds"
                filled solo hide-details flat
                background-color="grey500"
                class="rounded-r rounded-l-0"
                prepend-inner-icon="mdi-ticket-percent"
                @input="onUpdateItemOdds(item._id, $event)"
                @change="onUpdateItemOdds(item._id, $event)"
              />
            </v-col>

            <v-col cols="1">
              <v-btn
                height="100%" class="grey600" color="red"
                plain block @click="onRemoveItem(i, item)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="Object.keys(odds).length" class="mt-2" dense>
        <v-col cols="12" md="6">
          <v-sheet class="rounded pa-2 px-3 text--secondary" color="grey900">
            Box value without edge:
            <MarketPrice
              hide-zero-value
              class="primary500--text ml-1 font-weight-semibold"
              :market="market" :value="oddsEv"
            />
          </v-sheet>
        </v-col>

        <v-col v-if="hasOddsConflict" cols="12" md="6">
          <v-alert

            class="grey900 mb-0 red--text" dense light
          >
            <v-icon slot="prepend" left color="red">
              mdi-alert
            </v-icon>
            <h4 class="text-body-2">
              The item odds do not add up to 100%
            </h4>
          </v-alert>
        </v-col>
      </v-row>
    </v-card>

    <v-alert v-if="hasMarketConflict" type="error" light>
      <h4 class="text-body-2">
        One or more items do not have prices in the box market selected above.
      </h4>
      <p class="text-caption mb-0">
        This will prevent the box from being opened by users.
      </p>
    </v-alert>

    <v-card class="pa-5 mb-4" color="grey700">
      <v-row>
        <v-col cols="12">
          <h4 class="text-body-1 primary500--text font-weight-bold">
            Box price
          </h4>
          <p class="text-caption mb-1">
            The price is based on the contents of the box and their odds plus an edge.
            It updates live with item price changes.
          </p>
        </v-col>

        <v-col cols="12" md="6">
          <v-currency-field
            v-model="forms.content.edge"
            :options="{
              currency: null,
              allowNegative: false,
              precision: 2,
              valueRange: { max: 100, min: 0}
            }"
            placeholder="Box edge"
            hide-details solo flat
            background-color="grey500"
            append-icon="mdi-percent"
          >
            <v-img
              slot="prepend-inner" :aspect-ratio="1" width="1.5em" class="mr-2"
              :src="edgeImg"
            />
          </v-currency-field>

          <v-slider
            :value="forms.content.edge"
            hide-details dense ticks="always"
            :max="20e2" :min="0" :step="100"
            @input="onEdgeSliderInput"
            @start="edgeSliderActive = true"
            @end="onEdgeSliderInput($event); edgeSliderActive = false"
          />
        </v-col>

        <v-col cols="12" md="6" class="text-right">
          <MarketPrice
            v-if="market"
            hide-zero-value
            class="primary500--text text-h4 font-weight-bold"
            :market="market" :value="oddsEv * (1 + (forms.content.edge / 100e2))"
          />
          <h4 class="text-body-2">
            Final box price
          </h4>
        </v-col>
      </v-row>
    </v-card>

    <div v-if="diff && !isNew">
      <h2 class="text-subtitle-1 text-uppercase primary500--text mb-2 font-weight-bold">
        Changes
      </h2>

      <v-divider class="mb-4" />

      <div v-for="(edit, i) in diff" :key="i" class="mb-2">
        <h4 class="text-body-1 mb-2 d-inline-flex align-center mr-2">
          <span class="px-2 py-1" :class="edit.classes">
            <v-icon
              class="text-caption"
              fixed-width
              v-text="edit.icon"
            />
          </span>
          <span class="grey600 grey100--text py-1 px-2">
            <span v-if="edit.path">{{ edit.path.join('.') }}</span>
            <span v-show="edit.index !== undefined" class="yellow--text">[{{ edit.index }}]</span>
          </span>
        </h4>

        <span v-if="edit.kind === 'A'">
          <span class="grey200--text">{{ edit.item.lhs || '(falsy)' }}</span>
          <v-icon class="mx-2 blue500--text">mdi-arrow-right-thin</v-icon>
          <span class="grey100--text">{{ edit.item.rhs || '(falsy)' }}</span>
        </span>

        <span v-else-if="edit.kind !== 'D'">
          <span class="grey200--text">{{ edit.lhs || '(falsy)' }}</span>
          <v-icon class="mx-2 blue500--text">mdi-arrow-right-thin</v-icon>
          <span class="grey100--text">{{ edit.rhs || '(falsy)' }}</span>
        </span>

        <span v-else class="grey200--text">removed</span>
      </div>
    </div>

    <div class="text-right">
      <v-btn
        v-if="diff && !isNew" text small class="mr-4"
        @click="onReset"
      >
        Reset changes
      </v-btn>

      <v-btn
        color="green--gradient" light
        large @click="$emit('submit', forms)"
      >
        <v-icon class="mr-2">
          mdi-check
        </v-icon>
        Submit
      </v-btn>
    </div>
  </div>
</template>

<script>
import diff from 'deep-diff'
import axios from 'axios'
import FileDropArea from '@/components/FileDropArea'
import sortBy from 'lodash.sortby'

const DIFF_ICONS = {
  N: 'mdi-plus',
  D: 'mdi-minus-thick',
  E: 'mdi-pencil',
  A: 'mdi-layers-edit',
}

const DIFF_CLASSES = {
  N: 'green500--text',
  D: 'red--text red darken-5',
  E: 'blue200--text blue700',
  A: 'primary100--text primary700',
}

export default {
  components: {
    FileDropArea,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    markets: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
    isNew: Boolean,
  },
  data() {
    return {
      uploading: false,
      files: [],
      edgeSliderActive: false,
      forms: structuredClone(this.value),
    }
  },
  computed: {
    diff() {
      const diffs = diff(this.value, this.forms)

      return diffs?.map(i => ({
        ...i,
        icon: DIFF_ICONS[i.kind],
        classes: DIFF_CLASSES[i.kind],
      }))
    },
    odds() {
      return this.forms.content.odds
    },
    market() {
      const { market } = this.forms
      return this.markets.find(i => i._id === market)
    },
    hasMarketConflict() {
      const { market, forms: { content: { items } } } = this

      return items.some(i => {
        const marketPrice = i.prices.find(o => o.market === market._id)
        return !marketPrice?.price
      })
    },
    oddsEv() {
      return this.forms.content.items.reduce((acc, item) => acc + this.getItemEv(item), 0)
    },
    casePriceEv() {
      return 0
    },
    hasOddsConflict() {
      return Object
        .values(this.forms.content.odds)
        .reduce((acc, v) => acc + v, 0) !== 100e4
    },
    edgeImg() {
      const { edge } = this.forms.content

      if (edge < 5e2) {
        return 'https://cdn.betterttv.net/emote/6228cf4d06fd6a9f5be69f1d/2x'
      }

      if (edge < 10e2) {
        return 'https://cdn.betterttv.net/emote/5f957f1b58e96102e929e52c/2x'
      }

      if (edge < 15e2) {
        return 'https://cdn.betterttv.net/emote/611076f576ea4e2b9f762172/2x'
      }

      if (edge < 20e2) {
        return 'https://cdn.betterttv.net/emote/6231bc4806fd6a9f5be7367d/2x'
      }

      if (edge >= 20e2) {
        return 'https://cdn.betterttv.net/emote/61313ac2af28e956864b929e/2x'
      }

      return 'https://cdn.betterttv.net/emote/6228cf4d06fd6a9f5be69f1d/2x'
    },
  },
  watch: {
    files(files) {
      if (files.length) {
        this.files = []
      }
    },
    'forms.name': function onNameChange(val) {
      if (val && this.isNew) {
        this.forms.slug = val.toLowerCase().replaceAll(' ', '-').replace(/[^\w-]+/g, '')
      }
    },
    'forms.market': function onNameChange(val) {
      this.forms.content.items = sortBy(
        this.forms.content.items,
        i => i.prices.find(o => o.market === val)?.price,
      ).reverse()
    },
    value(val) {
      this.forms = structuredClone(val)
    },
  },
  methods: {
    async onIconSubmitted(files) {
      if (this.uploading) return

      const [file] = files
      if (!file) return

      this.uploading = true

      const { forms } = this

      if (forms.icon) {
        // await this.deleteVariantIcon()
        forms.icon = null
      }

      try {
        const fd = new FormData()
        fd.append('file', file)
        fd.append('metadata', JSON.stringify({ test: true }))

        const { data: { response: resp } } = await axios.post(
          `${CONFIG.api.url}/acp/items/upload-image`,
          fd,
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        const url = resp.variants.find(i => i.includes('/original'))

        forms.icon = url
      } catch (error) {
        let err = error

        if (error.isAxiosError) {
          err = err.response.data.response
        }

        this.$toast.error(err.message)
      } finally {
        this.uploading = false
      }
    },
    async onAddItem() {
      const modal = this.$modal.open(
        'box-select-items',
        { hasReturnValue: true, maxWidth: 1200 },
        { market: this.market, without: this.forms.content.items.map(i => i._id) },
      )

      const newItems = await modal.promise
      if (!newItems) return

      const { market } = this.forms

      this.forms.content.items.push(...newItems)
      this.forms.content.items = sortBy(this.forms.content.items, i => i.prices.find(o => o.market === market)?.price).reverse()
    },
    onUpdateItemOdds(itemId, val) {
      this.$set(this.forms.content.odds, itemId, val)
    },
    onRemoveItem(index, item) {
      this.forms.content.items.splice(index, 1)
      this.$delete(this.forms.content.odds, item._id)
    },
    getItemMarketPrice(item, marketId) {
      return item.prices.find(i => i.market === marketId)?.price ?? 0
    },
    getItemEv(item) {
      const { odds, market } = this
      const itemOdds = odds[item._id] ?? 0
      const itemPrice = item.prices.find(o => o.market === market._id)?.price ?? 0

      return Math.ceil((itemOdds / 100e4) * itemPrice)
    },
    onReset() {
      this.forms = structuredClone(this.value)
    },
    onEdgeSliderInput(e) {
      if (!this.edgeSliderActive) return
      this.forms.content.edge = e
    },
  },
}
</script>

<style lang="scss" scoped>
.item-variant-icon {
  background: rgba(black, 0.7);
}
</style>
